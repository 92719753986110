import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import api from '../../../services/api';
import { API_GUARD } from '../../../services/env';
import { useHistory } from 'react-router-dom';
import Header from '../../../components/Header';
import './Table/TableRow/styles.module.css';
import ButtonReverse from '../../../components/Buttons/ButtonReverse';
import styles from './styles.module.css';
import stylesModal from '../../../components/ModalConfirm/style.module.css';
import { mascaraCnpj } from '../../../services/helpers';
import * as moment from 'moment';
import './novoCss.css';
import Modal from '../../../components/Modal';
import ModalConfirm from '../../../components/ModalConfirm';


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'id', numeric: true, disablePadding: false, label: '#' },
  { id: 'codigo', numeric: true, disablePadding: false, label: 'Código' },
  { id: 'nome', numeric: false, disablePadding: false, label: 'Nome' },
  { id: 'documento', numeric: true, disablePadding: false, label: 'Documento' },
  { id: 'cadastro', numeric: false, disablePadding: false, label: 'Cadastro' },
  { id: 'bloqueado', numeric: false, disablePadding: false, label: 'Bloqueado' },
  { id: 'acoes', numeric: false, disablePadding: false, label: 'Ações' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };



  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='center'
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// const useToolbarStyles = makeStyles((theme) => ({
//   root: {
//     paddingLeft: theme.spacing(2),
//     paddingRight: theme.spacing(1),
//   },
//   highlight:
//     theme.palette.type === 'light'
//       ? {
//           color: theme.palette.secondary.main,
//           backgroundColor: lighten(theme.palette.secondary.light, 0.85),
//         }
//       : {
//           color: theme.palette.text.primary,
//           backgroundColor: theme.palette.secondary.dark,
//         },
//   title: {
//     flex: '1 1 100%',
//   },
// }));


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(1),
    backgroundColor: '#FFF'
  },
  table: {
    minWidth: 650,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));


export default function TableHistorico(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(200);
  const [rows, setRows] = React.useState([]);
  const [semHistorico, setSemHistorico] = React.useState(false);
  const [semResultado, setSemResultado] = React.useState(false);
  const [isAlertOn, setIsAlertOn] = React.useState(false);
  const [isBlockConfirmOn, setIsBlockConfirmOn] = React.useState(false);
  const [isDeleteConfirmOn, setIsDeleteConfirmOn] = React.useState(false);
  const [escondeButton, setEscondeButton] = React.useState(false);
  const [textSearch, setTextSearch] = React.useState('');
  const [rowsInit, setRowsInit] = React.useState('')

  const [alertModal, setAlertModal] = React.useState({
    title: "",
    text: ""
  });

  const [modalConfirmDelete, setModalConfirmDelete] = React.useState({
    title: "",
    text: ""
  });

  const [modalConfirmBlock, setModalConfirmBlock] = React.useState({
    title: "",
    text: ""
  });

  const history = useHistory();
  const access_token = localStorage.getItem("access_token");
  const theme = createMuiTheme({
  }, ptBR);

  const company = React.useRef({});


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };



  const handleChangePage = (event, newPage) => {
    console.log(event)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const isSelected = (name) => selected.indexOf(name) !== -1;

  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  async function listarHistorico() {
    setSemResultado(false)

    const response = await api.get(`/v1/${API_GUARD}/company`,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${access_token}`
        }
      });
    if (!response.data.companies || response.data.companies < 1) {
      setSemHistorico(true);
      
    } else {
      setSemHistorico(false);
      const itens = []
      var dados = response.data.companies;
      dados.map((item) =>
        itens.push(
          {
            id: item.id,
            codigo: item.code,
            nome: item.name,
            documento: item.document,
            cadastro: item.created_at,
            bloqueado: item.disabled
          })
      )
      setRows(itens)
      
    }
    
  }

  useEffect(() => {
    async function fetchBusinesses() {
      setSemResultado(false)
      const access_token = localStorage.getItem("access_token");

      const response = await api.get(`/v1/${API_GUARD}/company`,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
          }
        });
      if (!response.data.companies || response.data.companies < 1) {
        setSemHistorico(true);
      } else {
        setSemHistorico(false);
        const itens = []
        var dados = response.data.companies;
        dados.map((item) =>
          itens.push(
            {
              id: item.id,
              codigo: item.code,
              nome: item.name,
              documento: item.document,
              cadastro: item.created_at,
              bloqueado: item.disabled
            })
        )
        setRows(itens)
        setRowsInit(itens)
      }
    }
    fetchBusinesses();
  }, []);

  function handlerActionSelectChange(value, item) {
    company.current = item;

    switch (value) {
      case "edit":
        handlerHeaderEdit(item.id);
        break;
      case "recharge":
        handlerHeaderRecharge(item.id);
        break;
      case "signature":
        handlerHeaderSignature(item.id);
        break;
      case "block":
        handlerBlock(item);
        break;
      case "unblock":
        handlerUnblock(item);
        break;
      case "delete":
        handlerDelete(item);
        break;
      default:

    }
  }

  function handlerBlock() {
    toggleConfirm();
  }

  function handlerUnblock() {
    toggleConfirm();
  }

  function handlerHeaderEdit(id) {
    history.push(`/admin/empresa/${id}/editar`);
  }

  function handlerHeaderRecharge(id) {
    history.push(`/admin/company/${id}/adicionar-creditos`);
  }

  function handlerHeaderSignature(id) {
    history.push(`/admin/company/${id}/assinaturas`);
  }

  function handlerDelete() {
    toggleConfirmDelete();
  }



  function toggleConfirm() {
    if (company.current) {
      setModalConfirmBlock({
        title: !!company.current.bloqueado ? "Desbloquear" : "Bloquear",
        message: `Tem certeza que deseja ${!!company.current.bloqueado ? "desbloquear" : "bloquear"} a empresa ${company.current.nome}?`,
      })
      setIsBlockConfirmOn(true);
    } else {
      setModalConfirmBlock({
        title: "",
        message: "",
      })
      setIsBlockConfirmOn(false)
    }
  }

  function toggleConfirmDelete() {
    if (company.current) {
      setModalConfirmDelete({
        title: "Atenção!!!",
        message: `Tem certeza que deseja deletar a empresa ${company.current.nome}?`
      })
      setIsDeleteConfirmOn(true);
    } else {
      setModalConfirmDelete({
        title: "",
        message: "",
      })
      setIsDeleteConfirmOn(false);
    }
  }

  async function Deletar() {
    try {
      const access_token = await localStorage.getItem("access_token");
      const response = await api.delete(`/v1/${API_GUARD}/company/${company.current.id}`, {
        headers: { Authorization: "Bearer " + access_token }
      });
      if (response.status && response.status === 200) {
        company.current = null; 
        toggleConfirm();

        setAlertModal({
          title: response.data.title,
          message: response.data.message
        });

        setIsAlertOn(true);
      }
      listarHistorico()
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        const { errors } = data;
        switch (error.response.status) {
          case 400:
          case 404:
            setAlertModal({
              title: 'Ocorreu um erro',
              message: 'Empresa não encontrada'
            });
    
            setIsAlertOn(true);
            break;
          case 422:
            for (let key in errors) {
              setAlertModal({
                title: 'Ocorreu um erro',
                message: errors[key]
              });
      
              setIsAlertOn(true);
            }
            break;
          default:
        }
      }
    }
  }

  function Bloquear() {
    console.log(company.current)
    if (company.current) {
      if (!!company.current.bloqueado) {
        handlerUnblockCompany()
      } else {
        handlerBlockCompany()
      }
    }
  }

  async function handlerBlockCompany() {
    try {
      const access_token = await localStorage.getItem("access_token");
      const response = await api.post(`/v1/${API_GUARD}/company/${company.current.id}/block`, {}, {
        headers: { Authorization: "Bearer " + access_token }
      });
      if (response.status && response.status === 200) {
        company.current = null; 
        toggleConfirm();

        setAlertModal({
          title: response.data.title,
          message: response.data.message
        });

        setIsAlertOn(true);
      }
      listarHistorico()
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        const { errors } = data;
        switch (error.response.status) {
          case 400:
          case 404:
            setAlertModal({
              title: 'Ocorreu um erro',
              message: 'Erro ao bloquear a empresa, por favor, tente mais tarde.'
            });

            setIsAlertOn(true);
            break;
          case 422:
            for (let key in errors) {
              setAlertModal({
                title: 'Ocorreu um erro',
                message: errors[key]
              });
              
              setIsAlertOn(true);
            }
            break;
          default:
        }
      }
    }
  }

  async function handlerSearch(){

    
    const nameSearch = (value) =>{
      let str = value.nome.toLowerCase()
      let tamanho = textSearch.length
      str = str.slice(0, tamanho)
      return (str === textSearch.toLowerCase())
    }
    const codSearch = (value) =>{
      let str = String(value.codigo)
      let tamanho = textSearch.length
      str = str.slice(0, tamanho)
      return str === textSearch
    }
    const docSearch = (value) =>{
      let str = String(value.documento) 
      let tamanho = textSearch.length
      str = str.slice(0, tamanho)
      return str === textSearch
    }
    const filtroName = rowsInit.filter(nameSearch)
    const filtroCodigo = rowsInit.filter(codSearch)
    const filtroDocumento = rowsInit.filter(docSearch)
  

    const arr = []

    if(textSearch){

    if(filtroName[0]){
      filtroName.forEach((item)=>{
        arr.push(item)
      })
    }
    if(filtroCodigo[0]){
      filtroCodigo.forEach((item)=>{
        arr.push(item)
      })
    }
    if(filtroDocumento[0]){
      filtroDocumento.forEach((item)=>{
        arr.push(item)
      })
    }
    else{
      setRows(rowsInit)
    }
      
    setRows(arr)

    }else{
      setRows(rowsInit)
    }
    setTextSearch('')
  }
  async function handlerUnblockCompany() {
    try {
      const access_token = await localStorage.getItem("access_token");
      const response = await api.post(`/v1/${API_GUARD}/company/${company.current.id}/unblock`, {}, {
        headers: { Authorization: "Bearer " + access_token }
      });
      if (response.status && response.status === 200) {
        company.current = null; 
        toggleConfirm();

        setAlertModal({
          title: response.data.title,
          message: response.data.message
        });

        setIsAlertOn(true);
      }
      listarHistorico()
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        const { errors } = data;
        switch (error.response.status) {
          case 400:
          case 404:
            setAlertModal({
              title: 'Ocorreu um erro',
              message: 'Erro ao desbloquear a empresa, por favor, tente mais tarde.'
            });

            setIsAlertOn(true);
            break;
          case 422:
            for (let key in errors) {
              setAlertModal({
                title: 'Ocorreu um erro',
                message: errors[key]
              });
  
              setIsAlertOn(true);
            }
            break;
          default:
        }
      }
    }
  }
 

  return (
    <div className="tableEmpresa">
      <Header title="Empresas" description="Empresas cadastradas" />
      <div className={`${styles.body} ${styles.shadowBox}`}>
        <div className={styles.tableHeader}>
          <div className={styles.tableTitle}>
            <h2>Listagem de Empresas</h2>
          </div>
          <br />
          <div className={styles.tableOption}>
            {/* <Button text="Adicionar"
                type="button"
                onClick={() => { this.handlerHeaderCreateTelephone() }}
              /> */}
          </div>
          <div className={styles.search}>
            <input type='text' value={textSearch} onChange={(e)=>{setTextSearch(e.target.value)}}/>
            <div className={styles.searchs}>
              <button className={styles.btnSearch} onClick={ handlerSearch }>Buscar</button>
              <button className={styles.btnSearch} onClick={ ()=>{setRows(rowsInit)} }>Listar todos</button>
            </div>
            
          </div>
        </div>
        <ThemeProvider theme={theme}>
          {!semHistorico ?
            <div className="w-table">
              {!semResultado ?
                <Paper className={classes.paper}>
                   
                  <TableContainer>
                  
                    <Table
                      className={clsx(classes.table, "historico-empresa-table")}
                      aria-labelledby="tableTitle"
                      aria-label="enhanced table"
                    >
                    
                      <EnhancedTableHead
                        classes={classes}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                      />
                      {rows[0] ? 
                      <TableBody>
                        {stableSort(rows, getComparator(order, orderBy))
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.id}
                                selected={isItemSelected}
                              >
                                <TableCell className="export" component="th" id={labelId}>
                                  {row.id}
                                </TableCell>
                                <TableCell className="export" align="center">{row.codigo}</TableCell>
                                <TableCell className="export" align="center">{row.nome}</TableCell>
                                <TableCell className="export" align="center">{mascaraCnpj(row.documento)}</TableCell>
                                <TableCell className="export" align="center">{moment(row.cadastro).format("D/MM/Y HH:mm:ss")}</TableCell>
                                <TableCell className="export" align="center">{row.bloqueado ? 'Sim' : 'Não'}</TableCell>
                                <TableCell className="export" align="center">
                                  <select name="action" onChange={(event) => handlerActionSelectChange(event.target.value, row)} id="action" className={`form-control ${styles.select}`}>
                                    <option value="">Nenhuma</option>
                                    <option value="edit">Editar</option>
                                    <option value="recharge">Adicionar Créditos</option>
                                    <option value="signature">Assinaturas</option>
                                    {!!row.bloqueado ?
                                      (<option value="unblock">Desbloquear</option>) :
                                      (<option value="block">Bloquear</option>)
                                    }
                                    <option value="delete">Deletar</option>
                                  </select>
                                </TableCell>
                                {/* {isDeleteConfirmOn ?
                                  <div className={stylesModal.modal}>
                                    <div className={stylesModal.modalContainer}>
                                      <div className={stylesModal.modalHeader}>
                                        <h3>{modalConfirmDelete.title}</h3>
                                      </div>
                                      <div className={stylesModal.modalBody}>
                                        {
                                          <p>{modalConfirmDelete.message}</p>
                                        }
                                      </div>
                                      {!escondeButton ?
                                        <div className={stylesModal.modalFooter}>
                                          <ButtonReverse
                                            onClick={() => { toggleConfirm() }}
                                            text="Cancelar"
                                          />
                                          <div style={{ marginLeft: "10px" }} />
                                          <ButtonReverse
                                            onClick={() => { isBlockConfirmOn ? Bloquear(row.bloqueado) : Deletar() }}
                                            text="Confirmar"
                                          />
                                        </div>
                                        :
                                        <div className={stylesModal.modalFooter}>
                                          <ButtonReverse
                                            onClick={() => { toggleConfirm() }}
                                            text="Ok"
                                          />
                                        </div>
                                      }
                                    </div>
                                  </div>
                                  : ''} */}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    : <div className={styles.erro}>Nenhum registro encontrado</div>} 
                    </Table>
                    
                    <TablePagination
                    className="d-flex d-lg-none"
                      rowsPerPageOptions={[10, 20, 50, 100, 200]}
                      component="div"
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      labelRowsPerPage="Linhas"
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  
                  </TableContainer>
                   
                  <TablePagination
                    className="d-none d-lg-block"
                      rowsPerPageOptions={[10, 20, 50, 100, 200]}
                      component="div"
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      labelRowsPerPage="Resultados por página"
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
                : ''}
            </div>
            :
            <div className="historico-empresa-semHistorico">
              <h1>Nenhuma empresa foi resgistrada até o momento.</h1>
            </div>
          }
        </ThemeProvider>
      </div>

      <Modal
        isActive={isAlertOn}
        toggleAlert={() => setIsAlertOn(false)}
        title={alertModal.title}
        text={alertModal.message}
      />

      <ModalConfirm
        isActive={isBlockConfirmOn}
        toggleConfirm={() => { company.current = null; toggleConfirm(); }}
        handlerConfirm={() => Bloquear()}
        title={modalConfirmBlock.title}
        text={modalConfirmBlock.message}
      />

      <ModalConfirm
        isActive={isDeleteConfirmOn}
        toggleConfirm={() => { company.current = null; toggleConfirmDelete(); }}
        handlerConfirm={() => Deletar()}
        title={modalConfirmDelete.title}
        text={modalConfirmDelete.message}
      />
    </div>
  );
}